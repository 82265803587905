<template>
  <div class="sign-in" ref="resetPassword">
    <q-card class="sign-in__card --emphasize">
      <q-card-section class="text-center">
        <div class="flex items-center justify-center">
          <img
            :src="$store.state.branding.logoDark"
            style="width: 185px"
            class="q-my-md"
          />
        </div>
      </q-card-section>

      <template v-if="validatingToken && !tokenInvalid">
        <q-card-section class="flex justify-center q-mb-md">
          <loader />
        </q-card-section>
      </template>

      <template v-else-if="tokenInvalid">
        <div v-if="!newLinkSent">
          <q-card-section class="q-pt-none q-pb-sm">
            <p class="q-pb-xs text-weight-medium">
              {{ modeLanguageConfigs.invalidToken.header }}
            </p>
            <p
              v-if="userEmail.length > 0"
              class="q-mb-none text-font-secondary"
            >
              {{ modeLanguageConfigs.invalidToken.body }}
            </p>
          </q-card-section>

          <q-card-section
            v-if="(mode == 'create' && userEmail.length > 0) || mode == 'reset'"
          >
            <div class="sign-in__nav-container">
              <router-link
                v-if="mode == 'reset'"
                :to="{ name: 'SignIn' }"
                class="text-subtle"
              >
                Return to Sign In
              </router-link>
              <div v-else></div>
              <q-btn
                v-if="userEmail.length > 0"
                @click="sendNewLinkEmail"
                label="Request New Link"
                type="submit"
                color="primary"
                :disable="submittingRequest"
                :loading="submittingRequest"
              />
            </div>
          </q-card-section>
        </div>
        <div v-else>
          <q-card-section class="column items-center justify-center q-pt-none">
            <q-icon
              color="positive"
              size="xl"
              name="sym_r_check_circle"
              class="q-mb-sm --icon-filled"
            />
            <p class="text-weight-medium">
              {{ modeLanguageConfigs.invalidToken.successHeader }}
            </p>
            <small class="text-font-secondary">
              {{ userEmail }}
            </small>
          </q-card-section>
        </div>
      </template>

      <template v-else-if="!requestSent">
        <q-card-section class="q-py-none">
          <p class="q-pb-xs text-weight-medium">
            {{ modeLanguageConfigs.header }}
          </p>
          <p
            v-html="modeLanguageConfigs.body"
            class="q-mb-none text-font-secondary"
          />
        </q-card-section>
        <q-card-section>
          <q-form class="sign-in__form" @submit="submitPasswordResetRequest">
            <div class="row q-col-gutter-y-sm q-col-gutter-x-md">
              <div class="col-12">
                <q-input
                  filled
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  label="New Password"
                  lazy-rules
                  :rules="[
                    val => (val && val.length > 0) || 'Please enter a password.'
                  ]"
                  :disable="submittingRequest"
                  :error="invalidMessage !== ''"
                  :error-message="invalidMessage"
                  @focus="invalidMessage = ''"
                >
                  <template v-slot:append>
                    <q-icon
                      :name="
                        showPassword
                          ? 'sym_r_visibility'
                          : 'sym_r_visibility_off'
                      "
                      class="cursor-pointer"
                      @click="showPassword = !showPassword"
                    />
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  filled
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  v-model="passwordConfirmation"
                  label="Confirm Password"
                  lazy-rules
                  :rules="[
                    val =>
                      (val && val.length > 0) || 'Please confirm your password.'
                  ]"
                  :disable="submittingRequest"
                  :error="invalidMessage !== ''"
                >
                  <template v-slot:append>
                    <q-icon
                      :name="
                        showPasswordConfirmation
                          ? 'sym_r_visibility'
                          : 'sym_r_visibility_off'
                      "
                      class="cursor-pointer"
                      @click="
                        showPasswordConfirmation = !showPasswordConfirmation
                      "
                    />
                  </template>
                </q-input>
                <div class="text-font-secondary">
                  <p class="flex items-center">
                    <q-icon
                      :name="
                        passwordRules.hasMinCharacters
                          ? 'sym_r_check_circle'
                          : 'sym_r_cancel'
                      "
                      :color="
                        passwordRules.hasMinCharacters ? 'positive' : 'negative'
                      "
                      class="q-mr-xs"
                    />At least eight characters
                  </p>
                  <p class="flex items-center">
                    <q-icon
                      :name="
                        passwordRules.hasLetter
                          ? 'sym_r_check_circle'
                          : 'sym_r_cancel'
                      "
                      :color="passwordRules.hasLetter ? 'positive' : 'negative'"
                      class="q-mr-xs"
                    />At least one letter
                  </p>
                  <p class="flex items-center">
                    <q-icon
                      :name="
                        passwordRules.hasNumber
                          ? 'sym_r_check_circle'
                          : 'sym_r_cancel'
                      "
                      :color="passwordRules.hasNumber ? 'positive' : 'negative'"
                      class="q-mr-xs"
                    />At least one number
                  </p>
                  <p class="flex items-center">
                    <q-icon
                      :name="
                        passwordRules.hasSpecialCharacter
                          ? 'sym_r_check_circle'
                          : 'sym_r_cancel'
                      "
                      :color="
                        passwordRules.hasSpecialCharacter
                          ? 'positive'
                          : 'negative'
                      "
                      class="q-mr-xs"
                    />At least one special character
                  </p>
                </div>
              </div>
            </div>
            <div class="sign-in__nav-container q-mt-md">
              <router-link
                v-if="mode == 'reset'"
                :to="{ name: 'SignIn' }"
                style="text-decoration: none; color: #999"
              >
                Return to Sign In
              </router-link>
              <div v-else></div>
              <q-btn
                :label="modeLanguageConfigs.buttonText"
                type="submit"
                color="primary"
                :disable="submittingRequest"
                :loading="submittingRequest"
              />
            </div>
          </q-form>
        </q-card-section>
      </template>

      <template v-else>
        <q-card-section class="column items-center justify-center q-pt-none">
          <q-icon
            color="positive"
            size="xl"
            name="sym_r_check_circle"
            class="q-mb-sm --icon-filled"
          />
          <p class="text-weight-medium">
            {{ modeLanguageConfigs.successHeader }}
          </p>
          <small class="text-center" v-html="modeLanguageConfigs.successBody" />
        </q-card-section>
        <q-card-section>
          <div class="sign-in__nav-container q-mt-sm">
            <router-link
              :to="{ name: 'SignIn' }"
              style="text-decoration: none; color: #999"
            >
              {{ modeLanguageConfigs.successButtonText }}
            </router-link>
          </div>
        </q-card-section>
      </template>
    </q-card>
  </div>
</template>

<script>
import Loader from "@/components/UI/Loader";

export default {
  name: "CreateResetPassword",
  components: { Loader },
  props: {
    mode: {
      type: String,
      default: "reset"
    }
  },
  data() {
    return {
      userEmail: "",
      password: "",
      passwordConfirmation: "",
      passwordRules: {
        hasMinCharacters: false,
        hasLetter: false,
        hasNumber: false,
        hasSpecialCharacter: false
      },
      showPassword: false,
      showPasswordConfirmation: false,
      submittingRequest: false,
      invalidMessage: "",
      requestSent: false,
      validatingToken: true,
      tokenInvalid: false,
      newLinkSent: false,
      urlParams: new URLSearchParams(window.location.search)
    };
  },
  computed: {
    modeLanguageConfigs() {
      if (this.mode == "reset") {
        return {
          invalidToken: {
            header: "This link has expired.",
            body:
              "If you still need to reset your password, click the link below to request a new recovery link.",
            successHeader: "Recovery Link Sent"
          },
          header: "Reset Your Password",
          body:
            "Enter a new password below for the account associated with <b>" +
            this.userEmail +
            "</b>.",
          buttonText: "Reset Password",
          successHeader: "Password Reset",
          successBody:
            "The password for your account has been successfully updated.",
          successButtonText: "Return to Sign In"
        };
      } else {
        return {
          invalidToken: {
            header: "This link has expired.",
            body:
              "To finish creating your account, click the link below to request a new email verification link.",
            successHeader: "Verification Link Sent"
          },
          header: "Email Verified!",
          body:
            "We've successfully verified your email <b>" +
            this.userEmail +
            "</b>. Enter a new password below to finish creating your account.",
          buttonText: "Create Account",
          successHeader: "You're all set!",
          successBody:
            "Your account has been successfully created.<br> Click the link below to sign in.",
          successButtonText: "Go to Sign In"
        };
      }
    }
  },
  watch: {
    password(newVal) {
      this.passwordRules.hasMinCharacters = newVal.length >= 8;
      this.passwordRules.hasLetter = /[a-zA-Z]/.test(newVal);
      this.passwordRules.hasNumber = /\d/.test(newVal);
      this.passwordRules.hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
        newVal
      );
    }
  },
  mounted() {
    if (!this.urlParams.get("token")) {
      this.tokenInvalid = true;
    } else {
      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "validate-user-password-reset-token",
          params: {
            token: this.urlParams.get("token")
          }
        })
        .then(data => {
          this.userEmail = data.user_email;
          if (data.validated == 0) {
            this.tokenInvalid = true;
          }
        })
        .catch(() => {
          this.tokenInvalid = true;
        })
        .finally(() => {
          this.validatingToken = false;
        });
    }
  },
  methods: {
    submitPasswordResetRequest() {
      if (this.password !== this.passwordConfirmation) {
        this.invalidMessage = "Passwords do not match.";
        return;
      }

      if (Object.values(this.passwordRules).includes(false)) {
        this.invalidMessage = "Invalid password.";
        return;
      }

      this.submittingRequest = true;

      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "reset-user-password",
          params: {
            token: this.urlParams.get("token"),
            password: this.password,
            password_confirmation: this.passwordConfirmation
          }
        })
        .then(() => {
          this.requestSent = true;
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.invalidMessage = "Invalid password.";
          } else {
            this.invalidMessage = "An error occurred.";
          }
        })
        .finally(() => {
          this.submittingRequest = false;
        });
    },
    sendNewLinkEmail() {
      this.submittingRequest = true;
      let emailEndpoint =
        this.mode == "create"
          ? "send-user-verification-email"
          : "send-user-password-reset-email";

      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: emailEndpoint,
          params: {
            email: this.userEmail
          }
        })
        .then(() => {
          this.newLinkSent = true;
          this.submittingRequest = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-in {
  @include display-flex(column, center, center);
  @include fullscreen-height-w-titlebar();

  .sign-in__card {
    width: 500px;
  }

  .sign-in__nav-container {
    @include display-flex(row, space-between, center);
  }
}
</style>
